<template>
  <v-card :loading="isLoading" outlined class="d-flex flex-column">
    <div class="d-flex align-center pa-2">
      <span class="pa-1 body-1">Documentación {{ sub }} {{ idEstudio }}</span>
      <v-btn
        v-if="currentFolder"
        :disabled="currentFolder.parent === null"
        @click="currentFolder = [getFileByPath(currentFolder.parent)]"
        icon
        small
        class="mx-2"
      >
        <v-icon small>mdi-folder-arrow-up-outline</v-icon>
      </v-btn>

      <template v-for="({ text, path }, i) in breadcrumbs">
        <v-divider :key="i + '_div'" vertical></v-divider>
        <v-btn
          @click.stop="currentFolder = getFileByPath(path)"
          tile
          elevation="0"
          plain
          :key="i"
          >{{ text }}</v-btn
        >
      </template>

      <v-spacer></v-spacer>
      <v-btn small color="primary" @click.stop="getrootStructure">
        <v-icon left>mdi-reload</v-icon>
        Recargar
      </v-btn>
    </div>

    <v-divider></v-divider>

    <div class="d-flex">
      <div
        :style="{
          width: openFolderBrowser ? '300px' : '36px',
          minHeight: '200px',
        }"
      >
        <div class="d-flex pa-1">
          <v-btn @click="openFolderBrowser = !openFolderBrowser" icon small>
            <v-icon v-if="openFolderBrowser" small
              >mdi-arrow-collapse-left</v-icon
            >
            <v-icon v-if="!openFolderBrowser" small
              >mdi-arrow-collapse-right</v-icon
            >
          </v-btn>
        </div>
        <v-divider></v-divider>
        <Tree
          v-show="openFolderBrowser"
          :currentFolder="currentFolder"
          @changeFolder="(v) => (currentFolder = v)"
          :folders="rootStructure.children"
          @createFolder="createNewFolder"
          @deleteFolder="(v) => deleteFolder(v)"
          :sub="sub"
        />

        <v-dialog max-width="500" v-model="newFolderModal.show" persistent>
          <new-folder
            :key="newFolderModal.key"
            :path="currentPath"
            v-bind="{ ...newFolderModal }"
          />
        </v-dialog>

        <v-dialog max-width="500" v-model="newFileModal.show" persistent>
          <v-card>
            <drag-and-drop
              class="pa-2"
              :key="newFileModal.key"
              v-model="newFileModal.files"
              multiple
            ></drag-and-drop>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.stop="newFileModal.reject" color="error">
                <v-icon left>mdi-close</v-icon>
                Cancelar
              </v-btn>
              <v-btn
                @click.stop="newFileModal.resolve(newFileModal)"
                color="primary"
              >
                <v-icon left>mdi-upload</v-icon>
                Subir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-divider vertical></v-divider>

      <div class="flex-grow-1 d-flex flex-column">
        <div class="flex-grow-1" ref="detailsZone">
          <CurrentFolder
            :idEstudio="idEstudio"
            :currentFolder="currentFolder"
            @changeFolder="(v) => (currentFolder = v)"
            @createFolder="createNewFolder"
            @deleteFolder="(v) => deleteFolder(v)"
            @reload="getrootStructure"
            :sub="sub"
          />
        </div>

        <v-divider
          v-if="sub != 'final' || $root.acceso('EDITAR_ESTUDIOS')"
        ></v-divider>
        <div
          v-if="sub != 'final' || $root.acceso('EDITAR_ESTUDIOS')"
          class="d-flex flex-wrap pa-3 align-center mx-auto"
          style="gap: 10px"
        >
          <v-btn @click.stop="uploadFile">
            <v-icon left>mdi-upload</v-icon>
            Subir archivo</v-btn
          >
          <v-btn @click.stop="createNewFolder">
            <v-icon left>mdi-folder-plus</v-icon>
            Nueva carpeta</v-btn
          >
          <v-btn @click.stop="deleteFolder(currentFolder)">
            <v-icon left>mdi-folder-remove</v-icon>
            Eliminar carpeta</v-btn
          >
        </div>
      </div>
    </div>

    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import { ref, computed, onMounted, watch, watchEffect } from "vue";
import { refDefault } from "@vueuse/core";

export default {
  components: {
    Tree: () => import("./Tree.vue"),
    NewFolder: () => import("./NewFolder.vue"),
    CurrentFolder: () => import("./CurrentFolder.vue"),
    CurrentFile: () => import("./CurrentFile.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
  },
  props: {
    idEstudio: Number,
    sub: {
      type: String,
      default: "",
    },
  },
  setup(props, { root, emit }) {
    const rootStructure = ref({});

    const currentFolder = ref({});
    watchEffect(() => (currentFolder.value ??= rootStructure.value));

    const openFolderBrowser = ref(false);
    const newFolderModal = ref({
      show: false,
      path: "",
      name: null,
      is_file: false,
      resolve: null,
      reject: null,
      key: 0,
    });
    const newFileModal = ref({
      show: false,
      path: "",
      name: null,
      is_file: true,
      resolve: null,
      reject: null,
      key: 0,
      files: [],
    });

    const detailsZone = ref();
    const confirm = ref();

    const isLoading = ref(false);

    const currentPath = computed(() => currentFolder.value.path);

    function getFileByPath(path) {
      function f(a) {
        if (a.path === path) {
          result = a;
          return true;
        }
        return Array.isArray(a.children) && a.children.some(f);
      }
      var result;
      rootStructure.value.children.some(f);
      return result;
    }

    async function getrootStructure() {
      isLoading.value = true;
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/documentacion/${props.idEstudio}`,
        params: { sub: props.sub },
      });

      rootStructure.value = data;
      if (Object.keys(currentFolder.value).length == 0)
        currentFolder.value = rootStructure.value;
      if (currentPath.value)
        currentFolder.value = getFileByPath(currentPath.value);
      isLoading.value = false;

      emit("input", rootStructure.value.children);
    }

    onMounted(() => {
      if (!props.idEstudio) return;
      getrootStructure();
      detailsZone.value.addEventListener("dragenter", (e) => {
        e.preventDefault();
        uploadFile();
      });
    });

    async function createNewFolder() {
      const auxPromise = () =>
        new Promise((resolve, reject) => {
          newFolderModal.value.key = newFolderModal.value.key + 1;
          newFolderModal.value.name = "";
          newFolderModal.value.path = currentPath.value;
          newFolderModal.value.resolve = resolve;
          newFolderModal.value.reject = reject;
          newFolderModal.value.show = true;
        });

      isLoading.value = true;
      auxPromise()
        .then(async ({ fullpath }) => {
          await axios({
            url: `${process.env.VUE_APP_API_URL}/documentacion/${props.idEstudio}/${fullpath}`,
            method: "POST",
            params: { sub: props.sub },
          });

          emit("snack", `Carpeta ${fullpath} creada con éxito`);
          getrootStructure();
        })
        .catch(() => {})
        .finally(() => {
          newFolderModal.value.show = false;
          newFolderModal.value.name = "";
          newFolderModal.value.path = "";
          newFolderModal.value.resolve = null;
          newFolderModal.value.reject = null;
          isLoading.value = false;
        });
    }

    async function uploadFile() {
      const auxPromise = () =>
        new Promise((resolve, reject) => {
          newFileModal.value.key = newFileModal.value.key + 1;
          newFileModal.value.name = "";
          newFileModal.value.path = currentPath.value;
          newFileModal.value.resolve = resolve;
          newFileModal.value.reject = reject;
          newFileModal.value.files = [];
          newFileModal.value.show = true;
        });
      isLoading.value = true;

      auxPromise()
        .then(async ({ path: fullpath, files }) => {
          const formData = new FormData();
          Array.from(files).forEach((file) => {
            formData.append("files", file);
          });
          await axios({
            url: `${process.env.VUE_APP_API_URL}/documentacion/${props.idEstudio}/${fullpath}`,
            method: "POST",
            data: formData,
            params: { sub: props.sub },
          });

          emit(
            "snack",
            `${files.length} archivos subidos con éxito`,
            "success"
          );
        })
        .catch(() => {
          emit("snack", `Ha ocurrido un error al subir los archivos`, "error");
        })
        .finally(() => {
          newFileModal.value.show = false;
          newFileModal.value.name = "";
          newFileModal.value.path = "";
          newFileModal.value.resolve = null;
          newFileModal.value.reject = null;
          newFileModal.value.files = [];
          isLoading.value = false;

          getrootStructure();
        });
    }

    async function deleteFolder({ path, is_file }) {
      const confirmation = await confirm.value.open(
        "Eliminar " + path,
        `Estás seguro de que quieres eliminar ${
          is_file ? "este archivo" : "esta carpeta y todos sus contenidos"
        }`,
        { color: "error darken-1", confirmColor: "error darken-1" }
      );
      if (!confirmation) return;

      isLoading.value = true;
      await axios({
        url: `${process.env.VUE_APP_API_URL}/documentacion/${props.idEstudio}/${path}`,
        method: "DELETE",
        params: { sub: props.sub },
      });
      isLoading.value = false;

      getrootStructure();
    }

    const breadcrumbs = computed(() => {
      if (!currentPath.value) return [];
      // return currentPath.value
      //   ? ('\\' + currentPath.value)
      //       .split("\\")
      //       .slice(1)
      //       .map((seg) => ({ text: seg, disabled: false, path: seg }))
      //   : [];
      let pathArray = ("\\" + currentPath.value).split("\\");
      pathArray.shift();
      let itemsCount = pathArray.length;

      return pathArray.reduce((breadcrumbArray, path, idx) => {
        let count = idx + 2;
        breadcrumbArray.push({
          // to: {path:idx>1 ? "/" + breadcrumbArray[idx - 1].path + "/" : "/"+path },
          disabled: count > itemsCount,
          path: breadcrumbArray[idx - 1]
            ? breadcrumbArray[idx - 1].path + "\\" + path
            : path,
          text: path,
        });
        return breadcrumbArray;
      }, []);
    });

    return {
      isLoading,
      rootStructure,
      currentFolder,
      openFolderBrowser,
      newFolderModal,
      newFileModal,
      currentPath,
      getrootStructure,
      createNewFolder,
      uploadFile,
      deleteFolder,
      getFileByPath,
      detailsZone,
      confirm,
      breadcrumbs,
    };
  },
};
</script>
